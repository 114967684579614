import React, { useEffect, useRef, useState, useContext } from "react"
import styled from "styled-components"
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

import AppContext from "../context/AppContext"

import SecretPage from "./secretpage"

import posed from "react-pose"

import Logo from "../images/GRANDAIRE.svg"

const preContainer = posed.div({
  enter: {
    y: "-100%",
    opacity: 1,
    transition: {
      y: {
        ease: "easeInOut",
        duration: 500,
      },
      opacity: {
        ease: "easeInOut",
        duration: 1000,
      },
    },
  },
  leave: {
    y: "100%",
    opacity: 1,
    transition: {
      y: {
        ease: "easeInOut",
        duration: 500,
      },
    },
  },
})

const Container = styled(preContainer)`
  position: absolute;
  background: white;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  display: flex;
  justify-content: center;
  overflow-y: scroll;
`

const preForm = posed.form({
  enter: {
    y: "0",
    delayChildren: 450,
    staggerChildren: 50,
    delay: 450,
    opacity: 1,
    transition: {
      y: {
        ease: "easeInOut",
        duration: 1500,
      },
      opacity: {
        ease: "easeInOut",
        duration: 1500,
      },
    },
  },
  leave: {
    y: "5%",
    opacity: 1,
    transition: {
      y: {
        ease: "easeInOut",
        duration: 1000,
      },
    },
  },
})

const FormElement = posed.div({
  enter: {
    y: "0",
    opacity: 1,
    transition: {
      opacity: {
        ease: "easeInOut",
        duration: 1000,
      },
    },
  },
  leave: {
    // y: "0",
    // applyAtEnd: {
    //   y: "50%",
    // },
    opacity: 0,
  },
})

const Form = styled(preForm)`
  position: absolute;
  top: 0;
  text-align: center;
  width: 100%;
  padding-bottom: 8rem;

  > div:nth-child(1)  {
    position: fixed;
    left: 40px;
    top: 40px;
    z-index: 999;
  }

  div:nth-child(2)  {
    flex-basis: 100%;
    padding-top: 8rem;
  }
  div:nth-child(3)  {
    width: 25%;
    margin: 5rem auto 0 auto;
  }

  @media (min-width: 992px) {
    div:nth-child(3)  {
      width: 15%;
      margin: 5rem auto 0 auto;
    }
  }

  div:nth-child(4) {
    position: relative;
    width: 50%;
    margin: 0rem auto 1rem auto;
  }

  div:nth-child(5) {
    position: relative;
    width: 30%;
    margin: 1rem auto 3rem auto;
  }

  div:nth-child(6)  {
    flex-basis: 30%;
    margin: 0 auto;
    font-family: "Avenir";
    font-size: 0.7rem;
    line-height: 1rem;
    color: #646363;
    margin-top: 1rem;
    height: 1rem;
    letter-spacing: 0.1rem;
  }

  div:nth-child(7)  {
    flex-basis: 50%;
    font-family: "Avenir";
    color: rgb(144, 120, 85);
    text-decoration: underline;
    font-size: 0.7rem;
    line-height: 1rem;
    height: 1rem;
    letter-spacing: 0.1rem;

    span {
      opacity: 1;
      transition: opacity 0.5s;
      cursor: pointer;
    }

    span:hover {
      opacity: 0.4;
      transition: opacity 0.3s;
    }
  }

  input {
    font-family: "Avenir";
    border: 0;
    font-size: 0.7rem;
    letter-spacing: 0.2rem;
    text-align: center;
    width: 100%;
    color: #646363;
    border-bottom: 2px solid rgb(144, 120, 85);
    line-height: 2rem;
    // outline: none;
    margin-bottom: 1.5rem;
    border-radius: 0 !important;

    ::placeholder {
      color: #646363;
      opacity: 1;
    }
  }

  select {
    font-family: "Avenir";
    border: 0;
    font-size: 0.7rem;
    letter-spacing: 0.2rem;
    text-align: center;
    width: 100%;
    color: #646363;
    border-bottom: 2px solid rgb(144, 120, 85);
    line-height: 2rem;
    // outline: none;
    margin-bottom: 1.5rem;
    border-radius: 0;
    height: 2.25rem;
    background: white;
    -webkit-appearance: none;
    padding-left: 0.5rem;

    ::placeholder {
      color: #646363;
      opacity: 1;
    }
  }

  option {
    border-radius: 0;
  }

  textarea {
    font-family: "Avenir";
    border: 0;
    font-size: 0.7rem;
    letter-spacing: 0.1rem;
    text-align: center;
    color: #646363;
    width: 100%;
    border-bottom: 2px solid rgb(144, 120, 85);
    margin-top: 1.5rem;
    height: 2rem;
    // outline: none;
    resize: none;

    ::placeholder {
      color: #646363;
      opacity: 1;
    }
  }

  button {
    font-family: "Qualey";
    letter-spacing: 0.2rem;
    font-size: 1.1rem;
    border: 0;
    color: rgb(144, 120, 85);
    text-align: center;
    cursor: pointer;
    outline: none;
    background: none;
    opacity: 1;
  }

  button:hover {
    opacity: 0.8;
  }

  .arrow-down {
    position: absolute;
    right: 0;
    margin-top: 15px;
    border-left: 0.3rem solid transparent;
    border-right: 0.3rem solid transparent;
    border-top: 0.5rem solid rgb(144, 120, 85);
    pointer-events: none;
  }
`

const Title = styled.div`
  font-family: "Qualey";
  font-size: 1.1rem;
  line-height: 1.1rem;
  color: rgb(124, 98, 66);
  margin: 4rem 0;
`

const ErrorMessage = styled.div`
  font-family: "Avenir";
  font-size: 0.7rem;
  line-height: 1rem;
  color: #646363;
  height: 1rem;
`

const SuccessMessage = styled.div`
  font-family: "Avenir";
  font-size: 0.7rem;
  line-height: 1rem;
  color: #646363;
  margin-top: 1rem;
  height: 1rem;
`

const LogoContainer = styled.div`
  width: 250px;
  margin: 0 auto;
  img {
    height: 100%;
    width: 100%;
    margin: 0;
  }
`

const Kontakt = styled.div`
  font-family: "Qualey";
  letter-spacing: 0.2rem;
  font-size: 1.1rem;
  border: 1px solid rgb(144, 120, 85);
  width: 5rem;
  color: rgb(144, 120, 85);
  text-align: center;
  cursor: pointer;
  outline: none;
  background: none;
  opacity: 1;
  padding: 4rem 4rem;
  border-radius: 9999px;
  float: left;

  span {
    position: absolute;
    left: 25px;
    top: 60px;
  }
`

const LoginModule = props => {
  let intl = useIntl()
  let [unlockPage, setUnlockPage] = useState(false)
  const appContext = useContext(AppContext)

  useEffect(() => {
    //Kontakt Script

    // Declare variables

    var errorMessage = []

    var userData = {}

    // Check Password

    let checkPassword = e => {
      //Prevent default form submission
      e.preventDefault()

      //Get values

      userData = {
        password: {
          value: document.querySelector("#password-enter").value,
          isValid: false,
        },
      }

      //Check validity of fields

      checkValidity()

      //Check if all fields have been filled in

      if (userData.password.isValid) {
        setUnlockPage(true)
      } else {
        //Show error message
        document.querySelector(
          "#error-message-password"
        ).innerHTML = `${errorMessage}`
        document.querySelector("#error-message-password").style.display =
          "block"
      }
    }

    document
      .querySelector("#password-enter-form")
      .addEventListener("submit", checkPassword)

    //Validity function

    const checkValidity = () => {
      //Get values

      userData = {
        password: {
          value: document.querySelector("#password-enter").value,
          isValid: false,
        },
      }
      errorMessage = []

      // Check if password is correct
      userData.password.value === "grandaire"
        ? (userData.password.isValid = true)
        : errorMessage.push(`${intl.messages["password.error"]}`)

      if (userData.password.isValid) {
        document.querySelector("#error-message-password").innerHTML = ""
      } else {
        //Show error message
        document.querySelector(
          "#error-message-password"
        ).innerHTML = `${errorMessage}`
        document.querySelector("#error-message-password").style.display =
          "block"
      }
    }
  }, [])

  const getPasswordLink = () => {
    appContext.togglePasswordSend(true)
    props.isClickedKontakt()
  }

  return (
    <>
      <Container pose={props.isOpen ? "enter" : "leave"}>
        {!unlockPage ? (
          <Form id="password-enter-form">
            <FormElement>
              <Kontakt onClick={() => props.isClickedKontakt()}>
                <span>
                  {intl.formatMessage({
                    id: "kontaktform.title",
                  })}
                </span>
              </Kontakt>
            </FormElement>
            <FormElement>
              <LogoContainer>
                <img src={Logo} />
              </LogoContainer>
            </FormElement>
            <FormElement>
              <input
                type="password"
                name="Password"
                placeholder={intl.formatMessage({
                  id: "password.fieldpassword",
                })}
                id="password-enter"
              />
            </FormElement>
            <FormElement>
              <ErrorMessage id="error-message-password"></ErrorMessage>
            </FormElement>
            <FormElement>
              <button className="password-btn" id="password-btn">
                {intl.formatMessage({ id: "password.button" })}
              </button>
            </FormElement>
            <FormElement>
              <span>{intl.formatMessage({ id: "password.donthave" })}</span>
            </FormElement>
            <FormElement>
              <span onClick={() => getPasswordLink()}>
                {intl.formatMessage({ id: "password.donthavelink" })}
              </span>
            </FormElement>
          </Form>
        ) : (
          <SecretPage show={unlockPage} />
        )}
      </Container>
    </>
  )
}

export default LoginModule
