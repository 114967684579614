import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import _ from "lodash"

import LocomotiveScroll from "locomotive-scroll"

import posed from "react-pose"

import { TweenMax, TimelineMax, Power3, Expo, Circ, Power4 } from "gsap"

const IntroWrapper = styled.div``

const Container = styled.div`
  height: 300vh;
  width: 100vw;
  background-color: rgb(20, 20, 21);
  z-index: 998;
`

const PreTitle = styled.span`
  position: absolute;
  display: inline-block;
  width: 100vw;
`

const Spacer = styled.span`
  display: inline-block;
  width: 15vw;
`

const Title = styled.div`
  position: absolute;
  font-family: "Qualey";
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(124, 98, 66);
  font-size: 35rem;
  color: black;
  white-space: nowrap;
  color: transparent;
  height: 100%;
  display: flex;
  align-items: center;
`

const PreTitleContainer = styled.div`
  position: fixed;
  top: 50%;
  z-index: 999;
`

const preTitleContainer = posed.div({
  enter: {},
  leave: {
    transition: { ease: "easeOut", duration: 1000 },
  },
})

const TitleContainer = styled(preTitleContainer)`
  position: fixed;
  z-index: 999;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  pointer-events: none;
  overflow: hidden;
`

const StyledImg = styled.img`
  width: 80%;
  display: block;
  margin: 0 auto;
`

let prevScroll = 0

let scroll = null

let reset = false

const ScrollTextPre = props => {
  const preTitleRef = useRef()
  const titleRef = useRef()
  const containerRef = useRef()
  const titleContainerRef = useRef()
  const [titleWidth, setTitleWidth] = useState(0)
  const [scrollAmount, setScrollAmount] = useState(0)
  // const [deltaValue, setDeltaValue] = useState(0)
  let titleWidthValue = titleWidth
  let multiplicator = 1
  let hasTriggered = false
  let deltaValue = 0

  //  Initial Mount
  useEffect(() => {
    window.addEventListener("wheel", e => (deltaValue = e.deltaY))
    // Start Locomotive Scroll

    setTimeout(() => {
      scroll = new LocomotiveScroll({
        el: containerRef.current,
        smooth: true,
      })

      //Set Container Height to Title Width
      setTimeout(() => {
        titleWidthValue = Math.floor(
          titleRef.current.getBoundingClientRect().width
        )
        setTitleWidth(titleWidthValue)
      }, 0)

      //Attach Scroll Event
      scroll.on("scroll", e => {
        let currentScroll = e.scroll.y
        let delta = currentScroll - prevScroll

        updateScrollDistance(e)
        //Transition if reached limit

        prevScroll = currentScroll
        // if (e.limit === e.scroll.y && (deltaValue > 25 || delta > 0)) {
        if (e.limit === e.scroll.y && delta > 0) {
          if (hasTriggered) return
          //   scroll.destroy()
          props.scrollHasFinished()
          hasTriggered = true

          setTimeout(() => {
            hasTriggered = false
          }, 1000)
          // } else if (e.scroll.y === 0.1 && (deltaValue < -25 || delta < 0)) {
        } else if (e.scroll.y === 0 && delta < 0) {
          if (hasTriggered) return
          props.scrollHasFinished("previous")
          hasTriggered = true

          setTimeout(() => {
            hasTriggered = false
          }, 200)
        }
      })
    }, 1000)
  }, [])

  useEffect(() => {
    if (props.reset) {
      scroll.scrollTo(containerRef.current, 0)
    }
  })

  //  Set Scroll Distance Amount On Scroll
  const updateScrollDistance = _.throttle(e => {
    let scrollAmount = e.scroll.y

    setScrollAmount(scrollAmount / e.limit)
  }, 50)

  //  Move Title On Scroll Distance Amount Update

  useEffect(() => {
    TweenMax.to(titleRef.current, 2, {
      x: -scrollAmount * (titleWidthValue - window.innerHeight) * multiplicator,
      ease: Power4.easeOut,
    })
  }, [scrollAmount])

  const skipSection = () => {
    if (props.mouseIsRight) {
      if (scrollAmount === 1) {
        props.scrollHasFinished()
      } else {
        scroll.scrollTo(containerRef.current, scroll.scroll.instance.limit)
      }
    } else {
      if (scrollAmount === 0) {
        props.scrollHasFinished("previous")
      } else {
        scroll.scrollTo(containerRef.current, 0)
      }
    }
  }

  return (
    <IntroWrapper onClick={() => skipSection()}>
      <TitleContainer ref={titleContainerRef}>
        <Title ref={titleRef}>
          <Spacer className="spacer" />
          <span>ULTIMATE LUXURY</span>
        </Title>
      </TitleContainer>

      <Container ref={containerRef}></Container>
    </IntroWrapper>
  )
}

export default ScrollTextPre
