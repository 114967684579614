import React, { useEffect, useState, useRef, useContext } from "react"
import styled from "styled-components"

import posed from "react-pose"

import gsap, { TweenMax, Power3, Elastic } from "gsap"

import AppContext from "../context/AppContext"

const preContainer = posed.div({
  hideHotspot: {
    x: "-50%",
    y: "-100%",
    opacity: 1,
    transition: {
      y: {
        ease: "easeInOut",
        duration: 500,
      },
      opacity: {
        ease: "easeInOut",
        duration: 1000,
      },
    },
  },
  showHotspot: {
    x: "-50%",
    y: "100%",
    opacity: 1,
    transition: {
      y: {
        type: "spring",
        damping: 5,
        stiffness: 100,
        ease: "backInOut",
        duration: 100,
      },
    },
  },
})

const Container = styled(preContainer)`
  position: absolute;
  width: 70px;
  height: 70px;
  border-radius: 999px;
  left: 50%;
  top: 0;

  .hovered {
    background-color: rgba(255, 255, 255, 0) !important;
    transition: background-color 0.2s !important;
    cursor: pointer !important;
  }

  .hovered-outer {
    cursor: pointer !important;
  }
`

const Outer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 999px;
  background-color: white;
`

const Inner = styled.div`
  position: absolute;
  width: 20%;
  height: 20%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 999px;
  background-color: rgba(124, 98, 66, 1);
  transition: background-color 0.5s;
  border: 1px solid rgb(124, 98, 66);
`

const Hotspot = props => {
  const outerRef = useRef()
  const innerRef = useRef()
  const appContext = useContext(AppContext)

  useEffect(() => {
    outerAnimation()
    innerAnimation()
  }, [])

  const outerAnimation = action => {
    if (action === "enter") {
      TweenMax.to(outerRef.current, 1, {
        scale: 0.9,
        ease: Power3.easeOut,
      })
    } else {
      TweenMax.to(outerRef.current, 1, {
        scale: 1,
        ease: Elastic.easeOut,
      })
    }
  }

  const innerAnimation = action => {
    if (action === "enter") {
      TweenMax.to(innerRef.current, 1, {
        scale: 1.5,
        ease: Power3.easeOut,
      })
    } else {
      TweenMax.to(innerRef.current, 1, {
        scale: 1,
        ease: Elastic.easeOut,
      })
    }
  }

  const triggerEnter = () => {
    innerAnimation("enter")
    outerAnimation("enter")

    props.hotspotHovered("enter")

    innerRef.current.classList.add("hovered")
    outerRef.current.classList.add("hovered-outer")
  }

  const triggerLeave = () => {
    innerAnimation("leave")
    outerAnimation("leave")

    props.hotspotHovered("leave")

    innerRef.current.classList.remove("hovered")
    outerRef.current.classList.remove("hovered-outer")
  }

  const triggerOpenModule = page => {
    appContext.toggleHotspotClicked(true, page)
  }

  return (
    <Container
      pose={props.showHotspot ? "showHotspot" : "hideHotspot"}
      onMouseEnter={() => triggerEnter()}
      onMouseLeave={() => triggerLeave()}
      onClick={() => triggerOpenModule(props.page)}
    >
      <Outer ref={outerRef} />
      <Inner ref={innerRef} />
    </Container>
  )
}
export default Hotspot
