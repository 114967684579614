import React, { useEffect, useRef, useState, useContext } from "react"
import styled from "styled-components"
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

import AppContext from "../context/AppContext"

import Page from "./hotspotmodulepage"

import posed from "react-pose"

const preContainer = posed.div({
  enter: {
    y: "0",
    delayChildren: 450,
    staggerChildren: 50,
    delay: 450,
    opacity: 1,
    transition: {
      y: {
        ease: "easeInOut",
        duration: 1500,
      },
      opacity: {
        ease: "easeInOut",
        duration: 1500,
      },
    },
  },
  leave: {
    y: "5%",
    opacity: 0,
    transition: {
      y: {
        ease: "easeInOut",
        duration: 1000,
      },
    },
  },
})

const Container = styled(preContainer)`
  position: absolute;
  background: white;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  display: flex;
  justify-content: center;
  overflow-y: scroll;
`

const HotspotModule = props => {
  let containerRef = useRef()

  useEffect(() => {
    setTimeout(() => {
      containerRef.current.scrollTop = 0
    }, 500)
  })

  return (
    <>
      <Container ref={containerRef}>
        <Page page={props.page} data={props.data} />
      </Container>
    </>
  )
}

export default HotspotModule
