import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import { useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import posed from "react-pose"

import Logo from "../images/GRANDAIRE.svg"

const preContainer = posed.div({
  enter: {
    opacity: 1,
    transition: {
      y: {
        ease: "easeInOut",
        duration: 1500,
      },
      opacity: {
        ease: "easeInOut",
        duration: 1500,
      },
    },
  },
  leave: {
    opacity: 0,
    transition: {
      opacity: {
        ease: "easeInOut",
        duration: 1500,
      },
    },
  },
})

const preInnerContainer = posed.div({
  enterSecret: {
    opacity: 1,
    transition: {
      y: {
        ease: "easeInOut",
        duration: 1500,
      },
      opacity: {
        ease: "easeInOut",
        duration: 1500,
      },
    },
  },
  leaveSecret: {
    opacity: 0,
    transition: {
      opacity: {
        ease: "easeInOut",
        duration: 1500,
      },
    },
  },
})

const Container = styled(preContainer)`
  position: absolute;
  background: white;
  height: 100%;
  width: 100vw;
  z-index: 999;
`

const InnerContainer = styled(preInnerContainer)`
  position: absolute;
  background: white;
  height: 100%;
  width: 100vw;
  z-index: 999;
`

const LogoContainer = styled.div`
  width: 250px;
  margin: 4rem auto 0 auto;
  img {
    height: 100%;
    width: 100%;
    margin: 0;
  }
`

const BigTitle = styled.div`
  font-family: "Qualey";
  font-size: 3.5rem;
  line-height: 3.5rem;
  color: rgb(124, 98, 66);
  text-transform: uppercase;
  text-align: center;
  padding: 5rem 5%;

  @media (min-width: 1025px) {
    font-size: 7.5rem;
    line-height: 7.5rem;
    padding: 5rem 20%;
  }
`

const Text = styled.div`
  font-family: "Avenir";
  font-size: 1.25rem;
  line-height: 2rem;
  padding: 2.5rem 5% 7.5rem 5%;
  color: #646363;

  @media (min-width: 1025px) {
    padding: 2.5rem 20% 7.5rem 20%;
  }
`

const Gallery = styled.div`
  padding: 0rem 5% 7.5rem 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 450px;

  @media (min-width: 1025px) {
    padding: 0rem 20% 7.5rem 20%;
  }

  div {
    flex-basis: 33%;
    overflow: hidden;
  }

  div div:first-child {
    font-family: "Qualey";
    color: rgb(124, 98, 66);
    text-align: center;
  }

  div div:last-child {
    padding: 1rem 0.25rem;
    height: 100%;
  }

  div div:last-child div {
    height: 100%;
  }
`

const SmallTitle = styled.div`
  font-family: "Qualey";
  font-size: 2.5rem;
  line-height: 2.5rem;
  color: rgb(124, 98, 66);
  text-transform: uppercase;
  text-align: center;

  @media (min-width: 1025px) {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
`

const Sizes = styled.div`
  font-family: "Qualey";
  font-size: 3.5rem;
  line-height: 3.5rem;
  padding: 2.5rem 0% 2.5rem 0%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: rgb(124, 98, 66);
  display: flex;
  flex-wrap: wrap;
  min-height: 560px;

  @media (min-width: 1025px) {
    font-size: 5.5rem;
    line-height: 5.5rem;

    div span {
      font-size: 1rem;
      line-height: 1rem;
    }
  }

  @media (min-width: 1445px) {
    padding: 2.5rem 12% 2.5rem 12%;
  }

  div {
    flex-basis: 33%;
    text-align: center;
    padding: 2.5rem 0;
    transition: color 0.5s;
  }

  div a {
    color: rgba(255, 255, 255, 0);
    -webkit-text-stroke: 1px rgba(255, 255, 255, 1);
    text-align: center;
    transition: color 0.5s;
    text-decoration: none;
  }

  span {
    -webkit-text-stroke: 0;
    color: white;
    display: block;
    font-size: 1rem;
    line-height: 1rem;
    padding-top: 1rem;
    opacity: 0;
    transition: opacity 0.3s;
  }

  div:hover span {
    opacity: 1;
    transition: opacity 0.3s;
  }

  div:hover a {
    color: rgba(255, 255, 255, 1);
    transition: color 0.3s;
    cursor: pointer;
  }
`

const Contact = styled.div`
  padding: 10rem 0;
  div:nth-child(1) {
    font-family: "Qualey";
    font-size: 2.5rem;
    line-height: 2.5rem;
    color: rgb(124, 98, 66);
    text-transform: uppercase;
    text-align: center;

    @media (min-width: 1025px) {
      font-size: 2.5rem;
      line-height: 2.5rem;
    }
  }

  div:nth-child(2) {
    font-family: "Qualey";
    font-size: 1rem;
    line-height: 1rem;
    color: rgb(124, 98, 66);
    text-transform: uppercase;
    text-align: center;
    margin: 4rem 0 3rem 0;

    @media (min-width: 1025px) {
      font-size: 1rem;
      line-height: 1rem;
    }
  }

  div:nth-child(3) {
    font-family: "Qualey";
    font-size: 1rem;
    line-height: 1rem;
    color: rgb(124, 98, 66);
    text-transform: uppercase;
    text-align: center;
    margin: 1rem 0 1rem 0;

    span:nth-child(2) a {
      font-family: "Avenir";
      color: #646363;
      text-decoration: none;
      text-transform: none;
      padding-left: 0.5rem;
    }
  }

  div:nth-child(4) {
    font-family: "Qualey";
    font-size: 1rem;
    line-height: 1rem;
    color: rgb(124, 98, 66);
    text-transform: uppercase;
    text-align: center;
    margin: 1rem 0 1rem 0;

    span:nth-child(2) a {
      font-family: "Avenir";
      color: #646363;
      text-decoration: none;
      text-transform: none;
      padding-left: 0.5rem;
    }
  }
`

const SecretPage = props => {
  let intl = useIntl()
  let [showSecret, setShowSecret] = useState(false)

  const data = useStaticQuery(graphql`
    query {
      Four: file(relativePath: { eq: "slider/grandaire_004.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      Five: file(relativePath: { eq: "slider/grandaire_005.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      Seven: file(relativePath: { eq: "slider/grandaire_007.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      penthouseOne: file(relativePath: { eq: "pdf/GRANDAIRE-147-QM.zip" }) {
        publicURL
      }
      penthouseTwo: file(relativePath: { eq: "pdf/GRANDAIRE-156-QM.zip" }) {
        publicURL
      }
      penthouseThree: file(relativePath: { eq: "pdf/GRANDAIRE-192-QM.zip" }) {
        publicURL
      }
      penthouseFour: file(relativePath: { eq: "pdf/GRANDAIRE-199-QM.zip" }) {
        publicURL
      }
      penthouseFive: file(relativePath: { eq: "pdf/GRANDAIRE-305-QM.zip" }) {
        publicURL
      }
    }
  `)

  useEffect(() => {
    setShowSecret(true)
  }, [])

  console.log(data)

  return (
    <Container>
      <InnerContainer pose={showSecret ? "enterSecret" : "leaveSecret"}>
        <LogoContainer>
          <img src={Logo} />
        </LogoContainer>
        <BigTitle>
          {intl.formatMessage({
            id: "secret.title",
          })}
        </BigTitle>
        <Text>
          <span
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: "secret.textone" }),
            }}
          />
        </Text>
        <Gallery>
          <div>
            <div>CONTEMPORARY LUXURY</div>
            <div>
              <Img fluid={data.Five.childImageSharp.fluid} />
            </div>
          </div>
          <div>
            <div>ULTIMATE LUXURY</div>
            <div>
              <Img fluid={data.Seven.childImageSharp.fluid} />
            </div>
          </div>
          <div>
            <div>CONTEMPORARY LIVING</div>
            <div>
              <Img fluid={data.Four.childImageSharp.fluid} />
            </div>
          </div>
        </Gallery>
        <SmallTitle>THE FLOORPLANS</SmallTitle>
        <Text>
          <span
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: "secret.floorplanstext" }),
            }}
          />
        </Text>
        <Sizes>
          <div>
            <a href={data.penthouseOne.publicURL} download>
              147 QM <span>DOWNLOAD FLOORPLAN</span>
            </a>
          </div>
          <div>
            <a href={data.penthouseTwo.publicURL} download>
              156 QM <span>DOWNLOAD FLOORPLAN</span>
            </a>
          </div>
          <div>
            <a href={data.penthouseThree.publicURL} download>
              192 QM <span>DOWNLOAD FLOORPLAN</span>
            </a>
          </div>
          <div>
            <a href={data.penthouseFour.publicURL} download>
              199 QM <span>DOWNLOAD FLOORPLAN</span>
            </a>
          </div>
          <div>
            <a href={data.penthouseFive.publicURL} download>
              305 QM <span>DOWNLOAD FLOORPLAN</span>
            </a>
          </div>
        </Sizes>
        <Contact>
          <div>{intl.formatMessage({ id: "secret.contact" })}</div>
          <div>THOMAS VON MÜNSTER, ALEXANDER QUEDNAU, RALF SIMONEIT</div>
          <div>
            <span>T</span>
            <span>
              <a href="tel:+49 30 44353-130">+49 30 44353-130</a>
            </span>
          </div>
          <div>
            <span>M</span>
            <span>
              <a href="mailto:kontakt@grandaire.berlin">
                kontakt@grandaire.berlin
              </a>
            </span>
          </div>
          <div></div>
        </Contact>
      </InnerContainer>
    </Container>
  )
}

export default SecretPage
