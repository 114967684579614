import React, { useEffect, useRef, useState, useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import posed from "react-pose"

import Img from "gatsby-image"

import Logo from "../images/GRANDAIRE.svg"

import Map from "./map"

import ScrollArrowImg from "../images/scrollarrow.svg"

import ScrollTextPreMobile from "./scrolltextpremobile"
import ScrollTextOneMobile from "./scrolltextonemobile"
import ScrollTextTwoMobile from "./scrolltexttwomobile"
import ScrollTextThreeMobile from "./scrolltextthreemobile"
import ScrollTextFourMobile from "./scrolltextfourmobile"

import Hotspot from "./hotspot"

import AppContext from "../context/AppContext"

import "./flickity.css"

import Flickity from "flickity"

const preContainer = posed.div({
  enter: {
    x: 0,
    opacity: 1,
    transition: {
      x: {
        ease: "easeInOut",
        duration: 1000,
      },
      opacity: {
        ease: "easeOut",
        duration: 1000,
      },
    },
  },
  leave: {
    x: props => (props.nextDiv ? "-100%" : "100%"),
    opacity: 0,
    applyAtEnd: {
      x: props => (props.nextDiv ? "-100%" : "100%"),
      opacity: 0,
    },
    transition: {
      x: {
        ease: "easeInOut",
        duration: 1000,
      },
      opacity: {
        ease: "easeOut",
        duration: 1000,
      },
    },
  },
})

const Container = styled(preContainer)`
  .carousel  {
    height: 100vh;
    width: 100vw;
  }

  .carousel-cell {
    background-size: cover;
    height: 100vh;
  }

  .carousel-cell.map {
    width: 100%;
  }

  .carousel-cell.cell-one {
    background-size: cover;
    height: 100vh;
    width: 100vw;
  }

  .carousel-cell.image {
    background-size: cover;
    height: 100vh;
    width: 100vw;
  }
`

const StyledImgGatsby = styled(Img)`
  height: 100vh;
  width: 100vw;
`

const StyledImg = styled.img`
  position: absolute;
  left: 0;
  top: 50%;
  transform-origin: center center;
  transform: translateY(-50%) rotateZ(-90deg);
  margin-left: -10vh;
  width: 60vh;
`

const ScrollArrow = styled.div`
  position: fixed;
  height: 80px;
  top: 50%;
  right: 0;
  transform: translateY(-50%) rotateZ(-90deg);
  margin-right: 2rem;
  z-index: 999;
  img {
    height: 100%;
    width: 100%;
  }

  .scroll-arrow {
    animation: bounce 1s infinite linear alternate;
  }

  @keyframes bounce {
    from {
      transform: translateY(10px);
    }
    to {
      transform: translateY(0px);
    }
  }
`

let disableSkip = false

let timeout = null

let scrollSection = true

let hotspotModuleOpen = false

let loginModuleOpen = false

const SliderOne = props => {
  const flickityRef = useRef()
  const [isScrollSection, setIsScrollSection] = useState(true)
  const [resetAll, setResetAll] = useState(false)
  const [pageNumber, setPageNumber] = useState(0)
  const [showHotspot, setShowHotspot] = useState(false)
  const appContext = useContext(AppContext)

  let flkty

  const data = useStaticQuery(graphql`
    query {
      Intro: file(relativePath: { eq: "slider/grandaire_00.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2500, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      Map: file(relativePath: { eq: "slider/map.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      One: file(relativePath: { eq: "slider/grandaire_001.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2500, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      Two: file(relativePath: { eq: "slider/grandaire_002.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2500, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      Three: file(relativePath: { eq: "slider/grandaire_003.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2500, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      Four: file(relativePath: { eq: "slider/grandaire_004.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2500, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      Five: file(relativePath: { eq: "slider/grandaire_005.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2500, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      Six: file(relativePath: { eq: "slider/grandaire_006.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2500, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      Seven: file(relativePath: { eq: "slider/grandaire_007.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2500, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      Eight: file(relativePath: { eq: "slider/grandaire_008.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2500, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  useEffect(() => {
    //Is Hotpspot Or Login Module Open

    hotspotModuleOpen = appContext.hotspotClicked

    loginModuleOpen = appContext.loginClicked

    // Init Flickity
    flkty = new Flickity(flickityRef.current, {
      prevNextButtons: false,
      pageDots: false,
      draggable: true,
      freeScroll: true,
      freeScrollFriction: 0.05,
      wrapAround: true,
    })

    //Show/Hide Hotspot

    setTimeout(() => {
      flkty.resize()
    }, 1000)

    flkty.on("select", () => {
      if ([2].indexOf(flkty.selectedIndex) > -1) {
        props.showKontakt("show")
      }
    })

    flkty.on("scroll", () => {
      //Show/Hide Hotspot

      if (
        flkty.cells[2].target <=
          Math.abs(flkty.x) + flkty.cells[2].size.width / 2 &&
        Math.abs(flkty.x) <=
          flkty.cells[2].target + flkty.cells[2].size.width / 2
      ) {
        setPageNumber(0)
        setShowHotspot(true)
      } else if (
        flkty.cells[5].target <=
          Math.abs(flkty.x) + flkty.cells[5].size.width / 2 &&
        Math.abs(flkty.x) <=
          flkty.cells[6].target + flkty.cells[6].size.width / 2
      ) {
        setPageNumber(1)
        setShowHotspot(true)
      } else if (
        flkty.cells[8].target <=
          Math.abs(flkty.x) + flkty.cells[8].size.width / 2 &&
        Math.abs(flkty.x) <=
          flkty.cells[9].target + flkty.cells[9].size.width / 2
      ) {
        setPageNumber(2)
        setShowHotspot(true)
      } else if (
        flkty.cells[11].target <=
          Math.abs(flkty.x) + flkty.cells[11].size.width / 2 &&
        Math.abs(flkty.x) <=
          flkty.cells[12].target + flkty.cells[12].size.width / 2
      ) {
        setPageNumber(3)
        setShowHotspot(true)
      } else if (
        flkty.cells[14].target <=
          Math.abs(flkty.x) + flkty.cells[14].size.width / 2 &&
        Math.abs(flkty.x) <=
          flkty.cells[15].target + flkty.cells[15].size.width / 2
      ) {
        setPageNumber(4)
        setShowHotspot(true)
      } else {
        setShowHotspot(false)
      }
    })
  }, [])

  const hotspotHovered = action => {
    if (action === "enter") {
      //
    } else {
      //
    }
  }

  return (
    <>
      <Container pose={!props.introHasFinished ? "enter" : "leave"}>
        <div className="carousel" ref={flickityRef}>
          <div className="carousel-cell cell-one">
            <StyledImg src={Logo} />
            <ScrollArrow>
              <img src={ScrollArrowImg} className="scroll-arrow" />
            </ScrollArrow>
          </div>
          <div className="carousel-cell">
            <ScrollTextPreMobile isLandscape={props.isLandscape} />
          </div>
          <div
            className="carousel-cell image"
            // style={{
            //   backgroundImage: `url(
            //    ${data.One.childImageSharp.fluid.src}
            //   )`,
            // }}
          >
            <StyledImgGatsby
              loading="eager"
              fluid={data.Intro.childImageSharp.fluid}
            />
          </div>
          {/* <div
            className="carousel-cell image"
            // style={{
            //   backgroundImage: `url(
            //     ${data.Two.childImageSharp.fluid.src}
            //    )`,
            // }}
          >
            <StyledImgGatsby
              loading="eager"
              fluid={data.Map.childImageSharp.fluid}
            />
          </div> */}
          <div className="carousel-cell map">
            <Map />
          </div>
          <div className="carousel-cell">
            <ScrollTextOneMobile isLandscape={props.isLandscape} />
          </div>
          <div
            className="carousel-cell image"
            // style={{
            //   backgroundImage: `url(
            //    ${data.One.childImageSharp.fluid.src}
            //   )`,
            // }}
          >
            <StyledImgGatsby
              loading="eager"
              fluid={data.One.childImageSharp.fluid}
            />
          </div>
          <div
            className="carousel-cell image"
            // style={{
            //   backgroundImage: `url(
            //     ${data.Two.childImageSharp.fluid.src}
            //    )`,
            // }}
          >
            <StyledImgGatsby
              loading="eager"
              fluid={data.Two.childImageSharp.fluid}
            />
          </div>
          <div className="carousel-cell">
            <ScrollTextTwoMobile isLandscape={props.isLandscape} />
          </div>
          <div
            className="carousel-cell image"
            // style={{
            //   backgroundImage: `url(
            //     ${data.Three.childImageSharp.fluid.src}
            //    )`,
            // }}
          >
            <StyledImgGatsby
              loading="eager"
              fluid={data.Three.childImageSharp.fluid}
            />
          </div>
          <div
            className="carousel-cell image"
            // style={{
            //   backgroundImage: `url(
            //               ${data.Four.childImageSharp.fluid.src}
            //              )`,
            // }}
          >
            <StyledImgGatsby
              loading="eager"
              fluid={data.Four.childImageSharp.fluid}
            />
          </div>
          <div className="carousel-cell">
            <ScrollTextThreeMobile isLandscape={props.isLandscape} />
          </div>
          <div
            className="carousel-cell image"
            // style={{
            //   backgroundImage: `url(
            //               ${data.Five.childImageSharp.fluid.src}
            //              )`,
            // }}
          >
            <StyledImgGatsby
              loading="eager"
              fluid={data.Five.childImageSharp.fluid}
            />
          </div>
          <div
            className="carousel-cell image"
            // style={{
            //   backgroundImage: `url(
            //               ${data.Six.childImageSharp.fluid.src}
            //              )`,
            // }}
          >
            <StyledImgGatsby
              loading="eager"
              fluid={data.Six.childImageSharp.fluid}
            />
          </div>
          <div className="carousel-cell">
            <ScrollTextFourMobile isLandscape={props.isLandscape} />
          </div>
          <div
            className="carousel-cell image"
            // style={{
            //   backgroundImage: `url(
            //               ${data.Seven.childImageSharp.fluid.src}
            //              )`,
            // }}
          >
            <StyledImgGatsby
              loading="eager"
              fluid={data.Seven.childImageSharp.fluid}
            />
          </div>
          <div
            className="carousel-cell image"
            // style={{
            //   backgroundImage: `url(
            //               ${data.Eight.childImageSharp.fluid.src}
            //              )`,
            // }}
          >
            <StyledImgGatsby
              loading="eager"
              fluid={data.Eight.childImageSharp.fluid}
            />
          </div>
        </div>
        <Hotspot
          hotspotHovered={action => hotspotHovered(action)}
          page={pageNumber}
          showHotspot={showHotspot}
        />
      </Container>
    </>
  )
}

export default SliderOne
