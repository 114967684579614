import React, { useEffect, useRef, useState, useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import posed from "react-pose"

import Img from "gatsby-image"

import ScrollTextOne from "./scrolltextone"
import ScrollTextTwo from "./scrolltexttwo"
import ScrollTextThree from "./scrolltextthree"
import ScrollTextFour from "./scrolltextfour"
import ScrollTextPre from "./scrolltextpre"

import Map from "./map"

import SliderArrow from "../images/scrollarrow.svg"

import AppContext from "../context/AppContext"

import "./flickity.css"

import Flickity from "flickity"

import Hotspot from "./hotspot"

const preContainer = posed.div({
  enter: {
    x: 0,
    opacity: 1,
    transition: {
      x: {
        ease: "easeInOut",
        duration: 1000,
      },
      opacity: {
        ease: "easeOut",
        duration: 1000,
      },
    },
  },
  leave: {
    x: props => (props.nextDiv ? "-100%" : "100%"),
    opacity: 0,
    applyAtEnd: {
      x: props => (props.nextDiv ? "-100%" : "100%"),
      opacity: 0,
    },
    transition: {
      x: {
        ease: "easeInOut",
        duration: 1000,
      },
      opacity: {
        ease: "easeOut",
        duration: 1000,
      },
    },
  },
})

const Container = styled(preContainer)`
  .carousel  {
    height: 100vh;
    width: 100vw;
  }

  .carousel-cell {
    background-size: cover;
    height: 100vh;
    width: 100vw;
  }
`

const StyledImg = styled(Img)`
  height: 100vh;
  width: 100vw;
`

const Arrow = styled.div`
  position absolute;
  height: 50px;
  width: 50px;
  pointer-events: none;


  img {
    height: 100%;
    width: 100%
  }
`

let disableSkip = false

let timeout = null

let scrollSection = true

let hideArrow = false

let hotspotModuleOpen = false

let loginModuleOpen = false

let hasTriggeredInitial = false

const SliderOne = props => {
  const flickityRef = useRef()
  const arrowRef = useRef()
  const [isScrollSection, setIsScrollSection] = useState(true)
  const [pageNumber, setPageNumber] = useState(0)
  const [showHotspot, setShowHotspot] = useState(false)
  const [resetAll, setResetAll] = useState(false)
  const [resetPre, setResetPre] = useState(false)
  const appContext = useContext(AppContext)
  const [mouseIsRight, setMouseIsRight] = useState(false)

  let flkty

  const data = useStaticQuery(graphql`
    query {
      Intro: file(relativePath: { eq: "slider/grandaire_00.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2500, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      Map: file(relativePath: { eq: "slider/map.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      One: file(relativePath: { eq: "slider/grandaire_001.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2500, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      Two: file(relativePath: { eq: "slider/grandaire_002.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2500, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      Three: file(relativePath: { eq: "slider/grandaire_003.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2500, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      Four: file(relativePath: { eq: "slider/grandaire_004.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2500, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      Five: file(relativePath: { eq: "slider/grandaire_005.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2500, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      Six: file(relativePath: { eq: "slider/grandaire_006.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2500, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      Seven: file(relativePath: { eq: "slider/grandaire_007.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2500, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      Eight: file(relativePath: { eq: "slider/grandaire_008.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2500, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  useEffect(() => {
    //Is Hotpspot Or Login Module Open

    hotspotModuleOpen = appContext.hotspotClicked

    loginModuleOpen = appContext.loginClicked

    // Init Flickity
    flkty = new Flickity(flickityRef.current, {
      prevNextButtons: false,
      pageDots: false,
      draggable: false,
      selectedAttraction: 0.02,
      friction: 0.5,
      wrapAround: true,
    })

    //Change Slide On Scroll
    window.addEventListener("wheel", e => {
      if (!scrollSection && !hotspotModuleOpen && !loginModuleOpen) {
        if (e.deltaY > 15 && !disableSkip) {
          disableSkip = true
          flkty.next()
          timeout = setTimeout(() => {
            disableSkip = false
          }, 1000)
        } else if (e.deltaY < -15 && !disableSkip) {
          disableSkip = true
          flkty.previous()
          timeout = setTimeout(() => {
            disableSkip = false
          }, 1000)
        }
      }
    })

    //Parallax

    var imgs = document.querySelectorAll(".carousel-cell")

    flkty.on("scroll", (event, progress) => {
      for (let i = 0; flkty.slides.length; i++) {
        var img = imgs[i]
        if (flkty.slides[i] === undefined) return
        var x = (flkty.slides[i].target + flkty.x) * -(1 / 3)

        img.style.backgroundPosition = "" + x + "px center"
      }
    })

    //Change Slide On Arrow Click

    flkty.on("select", () => {
      if ([1].indexOf(flkty.selectedIndex) > -1) {
        hasTriggeredInitial = true
        setPageNumber(0)
        setShowHotspot(true)
      } else if ([4, 5].indexOf(flkty.selectedIndex) > -1) {
        setPageNumber(1)
        setShowHotspot(true)
      } else if ([7, 8].indexOf(flkty.selectedIndex) > -1) {
        setPageNumber(2)
        setShowHotspot(true)
      } else if ([10, 11].indexOf(flkty.selectedIndex) > -1) {
        setPageNumber(3)
        setShowHotspot(true)
      } else if ([13, 14].indexOf(flkty.selectedIndex) > -1) {
        setPageNumber(4)
        setShowHotspot(true)
      } else {
        setShowHotspot(false)
      }

      if ([0, 3, 6, 9, 12].indexOf(flkty.selectedIndex) > -1) {
        scrollSection = true
        // mouseMove()
      }

      if ([1, 13].indexOf(flkty.selectedIndex) > -1) {
        props.showKontakt("show")
      }
      if ([14].indexOf(flkty.selectedIndex) > -1) {
        props.showKontakt("hide")
      }

      // [15].indexOf(flkty.selectedIndex) > -1
      //   ? (hideArrow = true)
      //   : (hideArrow = false)
      if ([14].indexOf(flkty.selectedIndex) > -1) {
        setResetAll(true)

        setTimeout(() => {
          setResetAll(false)
        }, 500)
      }

      // if ([0].indexOf(flkty.selectedIndex) > -1) {
      //   setResetPre(true)

      //   setTimeout(() => {
      //     setResetPre(false)
      //   }, 500)
      // }
    })
  })

  const scrollHasFinished = e => {
    if (e === "previous") {
      flkty.previous()
      scrollSection = false
      return
    }
    flkty.next()
    scrollSection = false
  }

  const mouseMove = e => {
    if (!hasTriggeredInitial) {
      arrowRef.current.style.display = "none"
      document.querySelector(".carousel-container").style.cursor = "auto"
      return
    }

    if (arrowRef.current === null) return

    const windowCenter = window.innerWidth / 2
    arrowRef.current.style.display = "none"
    document.querySelector(".carousel-container").style.cursor = "auto"

    if (!hideArrow) {
      document.querySelector(".carousel-container").style.cursor = "none"
      arrowRef.current.style.display = "block"
      if ([0].indexOf(flkty.selectedIndex) > -1) {
        arrowRef.current.style.top = `${e.clientY - 20}px`
        arrowRef.current.style.left = `${e.clientX - 20}px`
        arrowRef.current.style.transform = "rotateZ(-90deg)"
        return
      }
      if (e.clientX > windowCenter) {
        arrowRef.current.style.top = `${e.clientY - 20}px`
        arrowRef.current.style.left = `${e.clientX - 20}px`
        arrowRef.current.style.transform = "rotateZ(-90deg)"
      } else if (e.clientX < windowCenter) {
        arrowRef.current.style.top = `${e.clientY - 20}px`
        arrowRef.current.style.left = `${e.clientX - 20}px`
        arrowRef.current.style.transform = "rotateZ(90deg)"
      }
    }
  }

  useEffect(() => {
    if (props.kontaktHovered) {
      hideArrow = true
    } else {
      hideArrow = false
    }
  })

  const changeSlide = e => {
    const windowCenter = window.innerWidth / 2
    if (!scrollSection && !hideArrow) {
      if (e.clientX > windowCenter) {
        flkty.next()
      } else if (e.clientX < windowCenter) {
        flkty.previous()
      }
    } else {
      if (e.clientX > windowCenter) {
        setMouseIsRight(true)
      } else if (e.clientX < windowCenter) {
        setMouseIsRight(false)
      }
    }
  }

  useEffect(() => {
    document
      .querySelector(".carousel-container")
      .addEventListener("click", changeSlide)

    window.addEventListener("mousemove", e => {
      mouseMove(e)
    })

    document
      .querySelector(".mapboxgl-ctrl-top-right")
      .addEventListener("mouseenter", () => hotspotHovered("enter"))
    document
      .querySelector(".mapboxgl-ctrl-top-right")
      .addEventListener("mouseleave", () => hotspotHovered())
  }, [])

  const hotspotHovered = action => {
    if (action === "enter") {
      hideArrow = true
    } else {
      hideArrow = false
    }
  }

  return (
    <>
      <Container
        pose={!props.introHasFinished ? "enter" : "leave"}
        className="carousel-container"
      >
        <div className="carousel" ref={flickityRef}>
          <div className="carousel-cell">
            <ScrollTextOne
              reset={resetAll}
              mouseIsRight={mouseIsRight}
              scrollHasFinished={() => scrollHasFinished()}
            />
          </div>
          <div
            className="carousel-cell image"
            style={{
              backgroundImage: `url(
               ${data.Intro.childImageSharp.fluid.src}
              )`,
            }}
          >
            {/* <Hotspot
              hotspotHovered={action => hotspotHovered(action)}
              page={0}
            /> */}
          </div>
          {/* <div
            className="carousel-cell image"
            style={{
              backgroundImage: `url(
                ${data.Map.childImageSharp.fluid.src}
               )`,
            }}
          ></div> */}
          <div className="carousel-cell">
            <Map isMobile={props.isMobile} />
          </div>
          <div className="carousel-cell">
            <ScrollTextPre
              reset={resetAll}
              mouseIsRight={mouseIsRight}
              scrollHasFinished={e => scrollHasFinished(e)}
            />
          </div>
          <div
            className="carousel-cell image"
            style={{
              backgroundImage: `url(
               ${data.One.childImageSharp.fluid.src}
              )`,
            }}
          >
            {/* <Hotspot
              hotspotHovered={action => hotspotHovered(action)}
              page={1}
            /> */}
          </div>
          <div
            className="carousel-cell image"
            style={{
              backgroundImage: `url(
                ${data.Two.childImageSharp.fluid.src}
               )`,
            }}
          >
            {/* <Hotspot
              hotspotHovered={action => hotspotHovered(action)}
              page={1}
            /> */}
          </div>
          <div className="carousel-cell">
            <ScrollTextTwo
              reset={resetAll}
              mouseIsRight={mouseIsRight}
              scrollHasFinished={e => scrollHasFinished(e)}
            />
          </div>
          <div
            className="carousel-cell image"
            style={{
              backgroundImage: `url(
                ${data.Three.childImageSharp.fluid.src}
               )`,
            }}
          >
            {/* <Hotspot
              hotspotHovered={action => hotspotHovered(action)}
              page={2}
            /> */}
          </div>
          <div
            className="carousel-cell image"
            style={{
              backgroundImage: `url(
                          ${data.Four.childImageSharp.fluid.src}
                         )`,
            }}
          >
            {/* <Hotspot
              hotspotHovered={action => hotspotHovered(action)}
              page={2}
            /> */}
          </div>
          <div className="carousel-cell">
            <ScrollTextThree
              reset={resetAll}
              mouseIsRight={mouseIsRight}
              scrollHasFinished={e => scrollHasFinished(e)}
            />
          </div>
          <div
            className="carousel-cell image"
            style={{
              backgroundImage: `url(
                          ${data.Five.childImageSharp.fluid.src}
                         )`,
            }}
          >
            {/* <Hotspot
              hotspotHovered={action => hotspotHovered(action)}
              page={3}
            /> */}
          </div>
          <div
            className="carousel-cell image"
            style={{
              backgroundImage: `url(
                          ${data.Six.childImageSharp.fluid.src}
                         )`,
            }}
          >
            {/* <Hotspot
              hotspotHovered={action => hotspotHovered(action)}
              page={3}
            /> */}
          </div>
          <div className="carousel-cell">
            <ScrollTextFour
              reset={resetAll}
              mouseIsRight={mouseIsRight}
              scrollHasFinished={e => scrollHasFinished(e)}
            />
          </div>
          <div
            className="carousel-cell image"
            style={{
              backgroundImage: `url(
                          ${data.Seven.childImageSharp.fluid.src}
                         )`,
            }}
          >
            {/* <Hotspot
              hotspotHovered={action => hotspotHovered(action)}
              page={4}
            /> */}
          </div>
          <div
            className="carousel-cell image"
            style={{
              backgroundImage: `url(
                          ${data.Eight.childImageSharp.fluid.src}
                         )`,
            }}
          >
            {/* <Hotspot
              hotspotHovered={action => hotspotHovered(action)}
              page={4}
            /> */}
          </div>
          {/* <div className="carousel-cell"></div> */}
        </div>
      </Container>
      <Arrow ref={arrowRef}>
        <img src={SliderArrow} />
      </Arrow>
      <Hotspot
        hotspotHovered={action => hotspotHovered(action)}
        page={pageNumber}
        showHotspot={showHotspot}
      />
    </>
  )
}

export default SliderOne
