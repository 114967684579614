import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import _ from "lodash"

import LocomotiveScroll from "locomotive-scroll"

import Logo from "../images/GRANDAIRE.svg"

import ScrollArrowImg from "../images/scrollarrow.svg"

import posed from "react-pose"

import { TweenMax, TimelineMax, Power3, Expo, Circ, Power4 } from "gsap"
// import ScrollMagic from "scrollmagic"
// import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap"
// ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax)

// const preIntroWrapper = posed.div({
//   enter: {
//     x: 0,
//   },
//   leave: {
//     x: "-100%",
//     transition: { ease: "easeOut", duration: 1000 },
//   },
// })

// const IntroWrapper = styled(preIntroWrapper)`
//   position: fixed;
// `

const IntroWrapper = styled.div`
  // position: fixed;
`

const Container = styled.div`
  height: 300vh;
  width: 200vw;
  min-width: 200vw;
  background-color: rgb(20, 20, 21);
  z-index: 0;
`

const PreTitle = styled.span`
  position: absolute;
  display: inline-block;
  width: 100vw;
`

const Spacer = styled.span`
  display: inline-block;
  width: 100vw;
`

const Title = styled.div`
  position: absolute;
  font-family: "Qualey";
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(124, 98, 66);
  font-size: 35rem;
  color: black;
  white-space: nowrap;
  color: transparent;
  height: 100%;
  display: flex;
  align-items: center;
`

const PreTitleContainer = styled.div`
  position: fixed;
  top: 50%;
  z-index: 999;
`

const preTitleContainer = posed.div({
  enter: {},
  leave: {
    transition: { ease: "easeOut", duration: 1000 },
  },
})

const TitleContainer = styled(preTitleContainer)`
  position: fixed;
  z-index: 999;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  pointer-events: none;
  overflow: hidden;
`

const StyledImg = styled.img`
  width: 80%;
  display: block;
  margin: 0 auto;
`

const ScrollArrow = styled.div`
  position: fixed;
  height: 80px;
  width: 80px;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  margin-bottom: 2rem;
  z-index: 999;
  img {
    height: 100%;
    width: 100%;
  }

  .scroll-arrow {
    animation: bounce 1s infinite linear alternate;
  }

  @keyframes bounce {
    from {
      transform: translateY(10px);
    }
    to {
      transform: translateY(0px);
    }
  }
`

let prevScroll = 0

let scroll = null

const ScrollTextOne = props => {
  const preTitleRef = useRef()
  const titleRef = useRef()
  const containerRef = useRef()
  const scrollArrowRef = useRef()
  const titleContainerRef = useRef()
  const [titleWidth, setTitleWidth] = useState(0)
  const [scrollAmount, setScrollAmount] = useState(0)
  const [showIntro, setShowIntro] = useState(true)
  let titleWidthValue = titleWidth
  let multiplicator = 1
  let hasTriggered = false

  let logoHasTriggered = false

  //  Initial Mount
  useEffect(() => {
    // Start Locomotive Scroll

    scroll = new LocomotiveScroll({
      el: containerRef.current,
      smooth: true,
    })

    //Set Container Height to Title Width
    setTimeout(() => {
      titleWidthValue = Math.floor(
        titleRef.current.getBoundingClientRect().width
      )
      setTitleWidth(titleWidthValue)
    }, 0)

    //Attach Scroll Event
    scroll.on("scroll", e => {
      if (props.reset) return
      let currentScroll = e.scroll.y
      let delta = currentScroll - prevScroll

      updateScrollDistance(e)
      //Transition if reached limit

      prevScroll = currentScroll
      if (e.limit === e.scroll.y && delta >= 0) {
        if (hasTriggered) return
        // scroll.scrollTo("top")
        // scroll.destroy()

        props.scrollHasFinished()
        hasTriggered = true

        setTimeout(() => {
          hasTriggered = false
        }, 200)
      }
    })
  }, [])

  useEffect(() => {
    if (props.reset) {
      scroll.scrollTo(containerRef.current, 0)
    }
  })

  //  Set Scroll Distance Amount On Scroll
  const updateScrollDistance = _.throttle(e => {
    // Rotate logo

    if (!logoHasTriggered) {
      TweenMax.to(".spacer", 2, {
        width: window.innerWidth / 4,
        ease: Power4.easeOut,
      })

      TweenMax.to(scrollArrowRef.current, 1, {
        y: "200%",
      })

      TweenMax.to(".preTitle", 2, {
        rotation: -90,
        x: -(window.innerWidth / 15),
        width: "80vh",
        ease: Power4.easeOut,
      })

      logoHasTriggered = true
    }

    let scrollAmount = e.scroll.y

    setScrollAmount(scrollAmount / e.limit)
  }, 50)

  //  Move Title On Scroll Distance Amount Update

  useEffect(() => {
    TweenMax.to(titleRef.current, 2, {
      x:
        -scrollAmount *
        (titleWidthValue - window.innerWidth - window.innerHeight) *
        multiplicator,
      ease: Power4.easeOut,
    })
  }, [scrollAmount])

  const skipSection = () => {
    if (!logoHasTriggered) {
      TweenMax.to(".spacer", 2, {
        width: window.innerWidth / 4,
        ease: Power4.easeOut,
      })

      TweenMax.to(scrollArrowRef.current, 1, {
        y: "200%",
      })

      TweenMax.to(".preTitle", 2, {
        rotation: -90,
        x: -(window.innerWidth / 15),
        width: "80vh",
        ease: Power4.easeOut,
      })

      logoHasTriggered = true
      return
    }

    scroll.scrollTo(containerRef.current, scroll.scroll.instance.limit)
  }

  return (
    <IntroWrapper onClick={() => skipSection()}>
      <TitleContainer ref={titleContainerRef}>
        <Title ref={titleRef}>
          <PreTitle className="preTitle" ref={preTitleRef}>
            <StyledImg src={Logo} />
          </PreTitle>
          <Spacer className="spacer" />
          <span>WELCOME HOME</span>
        </Title>
      </TitleContainer>
      <ScrollArrow ref={scrollArrowRef}>
        <img src={ScrollArrowImg} className="scroll-arrow" />
      </ScrollArrow>
      <Container ref={containerRef} className="container-intro"></Container>
    </IntroWrapper>
  )
}

export default ScrollTextOne
