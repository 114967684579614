import React, { useEffect, useRef, useState, useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import LoginModule from "./loginmodule"

import KontaktModule from "./kontaktmodule"

import posed from "react-pose"

import AppContext from "../context/AppContext"

const preSemiCircle = posed.div({
  enter: {
    y: "0%",
    x: "-50%",
    opacity: 1,
    delay: 1000,
    transition: {
      y: {
        ease: "easeInOut",
        duration: 250,
      },
      opacity: {
        ease: "easeInOut",
        duration: 250,
      },
    },
  },
  leave: {
    y: "100%",
    x: "-50%",
    opacity: 0,
  },
})

const preSemiCircleClose = posed.div({
  enter: {
    y: "0%",
    x: "-50%",
    opacity: 1,
    transition: {
      y: {
        ease: "easeInOut",
        duration: 350,
      },
      opacity: {
        ease: "easeInOut",
        duration: 350,
      },
    },
  },
  leave: {
    y: "100%",
    x: "-50%",
    opacity: 1,
    transition: {
      y: {
        ease: "easeIn",
        duration: 250,
      },
      opacity: {
        ease: "easeInOut",
        duration: 350,
      },
    },
  },
})

const SemiCircle = styled(preSemiCircle)`
  position: absolute;
  background: white;
  bottom: 0;
  height: 60px;
  width: 120px;
  left: 50%;
  border-top-left-radius: 150px;
  border-top-right-radius: 150px;
  z-index: 1;
  cursor: pointer;

  span {
    position: absolute;
    font-family: "Qualey";
    font-size: 18px;
    top: 25px;
    left: 29px;
    letter-spacing: 0.1rem;
    color: rgb(124, 98, 66);
    opacity: 1;
    transition: opacity 0.5s;
  }

  :hover span {
    opacity: 0.4;
    transition: opacity 0.3s;
  }
`

const SemiCircleClose = styled(preSemiCircleClose)`
  position: absolute;
  background-color: rgb(124, 98, 66);
  bottom: 0;
  height: 60px;
  width: 120px;
  left: 50%;
  border-top-left-radius: 150px;
  border-top-right-radius: 150px;
  z-index: 999;
  cursor: pointer;

  span {
    position: absolute;
    font-family: "Qualey";
    font-size: 18px;
    top: 25px;
    left: ${props => (props.isEnglish ? "31px" : "26px")};
    letter-spacing: 0.1rem;
    color: white;

    opacity: 1;
    transition: opacity 0.5s;
  }

  :hover span {
    opacity: 0.4;
    transition: opacity 0.3s;
  }
`

const SemiCircleCloseKontakt = styled(preSemiCircleClose)`
  position: absolute;
  background-color: rgb(124, 98, 66);
  bottom: 0;
  height: 60px;
  width: 120px;
  left: 50%;
  border-top-left-radius: 150px;
  border-top-right-radius: 150px;
  z-index: 999;
  cursor: pointer;

  span {
    position: absolute;
    font-family: "Qualey";
    font-size: 18px;
    top: 25px;
    left: ${props => (props.isEnglish ? "31px" : "26px")};
    letter-spacing: 0.1rem;
    color: white;

    opacity: 1;
    transition: opacity 0.5s;
  }

  :hover span {
    opacity: 0.4;
    transition: opacity 0.3s;
  }
`

const preImpressum = posed.div({
  enter: {
    y: "0%",
    opacity: 1,
    delay: 1000,
    transition: {
      y: {
        ease: "easeInOut",
        duration: 250,
      },
      opacity: {
        ease: "easeInOut",
        duration: 250,
      },
    },
  },
  leave: {
    y: "100%",
    opacity: 0,
  },
})

const Impressum = styled(preImpressum)`
  position: absolute;
  font-family: "Qualey";
  font-size: 18px;
  color: rgb(124, 98, 66);
  left: 0;
  bottom: 0;
  padding-left: 30px;
  padding-bottom: 9px;
  letter-spacing: 0.1rem;
  z-index: 9998;
  cursor: pointer;

  a {
    text-decoration: none;
    color: rgb(124, 98, 66);

    opacity: 1;
    transition: opacity 0.5s;
  }

  a:hover {
    opacity: 0.4;
    transition: opacity 0.3s;
  }
`

const preLanguageSwitch = posed.div({
  enter: {
    y: "0%",
    opacity: 1,
    delay: 1000,
    transition: {
      y: {
        ease: "easeInOut",
        duration: 250,
      },
      opacity: {
        ease: "easeInOut",
        duration: 250,
      },
    },
  },
  leave: {
    y: "100%",
    opacity: 0,
  },
})

const LanguageSwitch = styled(preLanguageSwitch)`
  position: absolute;
  font-family: "Qualey";
  font-size: 18px;
  color: rgb(124, 98, 66);
  right: 0;
  bottom: 0;
  padding-right: 30px;
  padding-bottom: 9px;
  letter-spacing: 0.1rem;
  z-index: 9998;
  cursor: pointer;

  a {
    color: rgb(124, 98, 66);
    text-decoration: none;

    opacity: 1;
    transition: opacity 0.5s;
  }

  a:hover {
    opacity: 0.4;
    transition: opacity 0.3s;
  }
`

const LoginButton = props => {
  const [isClicked, setIsClicked] = useState(false)
  const [isClickedKontakt, setIsClickedKontakt] = useState(false)
  const [isEnglish, setIsEnglish] = useState(false)
  const appContext = useContext(AppContext)

  useEffect(() => {
    if (props.data.locale === "de") {
      setIsEnglish(false)
    } else {
      setIsEnglish(true)
    }
  })

  let toggleKontaktHover = action => {
    if (action === "enter") {
      props.kontaktHovered("enter")
    } else {
      props.kontaktHovered("leave")
    }
  }

  let toggleKontaktClick = () => {
    setIsClickedKontakt(!isClickedKontakt)
  }

  let toggleLoginClick = () => {
    appContext.toggleLoginClicked(!isClicked)
    setIsClicked(!isClicked)
  }

  return (
    <>
      <Impressum
        pose={props.showKontakt ? "enter" : "enter"}
        onMouseEnter={() => toggleKontaktHover("enter")}
        onMouseLeave={() => toggleKontaktHover("leave")}
      >
        <a href="http://grandaire.berlin/de/impressum/" target="_blank">
          {props.data.messages.imprint}
        </a>
      </Impressum>
      <LanguageSwitch
        pose={props.showKontakt ? "enter" : "enter"}
        onMouseEnter={() => toggleKontaktHover("enter")}
        onMouseLeave={() => toggleKontaktHover("leave")}
      >
        <a
          href={
            isEnglish
              ? "/subsites/grandaire-penthouses"
              : "/subsites/grandaire-penthouses/en"
          }
        >
          {props.data.messages.languageSwitch}
        </a>
      </LanguageSwitch>
      <SemiCircle
        pose={props.showKontakt ? "enter" : "leave"}
        onClick={() => toggleLoginClick()}
        onMouseEnter={() => toggleKontaktHover("enter")}
        onMouseLeave={() => toggleKontaktHover("leave")}
      >
        <span>{props.data.messages.contact}</span>
      </SemiCircle>
      <LoginModule
        isOpen={isClicked}
        isClickedKontakt={() => toggleKontaktClick()}
      />
      <KontaktModule isOpen={isClickedKontakt} />
      <SemiCircleClose
        pose={isClicked ? "enter" : "leave"}
        onClick={() => toggleLoginClick()}
        isEnglish={isEnglish}
      >
        <span>{props.data.messages.close}</span>
      </SemiCircleClose>
      <SemiCircleCloseKontakt
        pose={isClickedKontakt ? "enter" : "leave"}
        onClick={() => setIsClickedKontakt(false)}
        isEnglish={isEnglish}
      >
        <span>{props.data.messages.close}</span>
      </SemiCircleCloseKontakt>
    </>
  )
}

export default LoginButton
