import React, { useEffect } from "react"
import styled from "styled-components"

import Logo from "../images/GRANDAIRE.svg"

import Phone from "../images/Phone.svg"

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgb(20, 20, 21);
  z-index: 9999;

  .mobile-logo {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 160px;
    height: 75px;

    img {
      height: 100%;
      width: 100%;
    }
  }

  //Circle CSS

  #container {
    position: fixed;
    width: 100%;
    margin: 0%;
    bottom: 0;
    margin-bottom: -5rem;
  }

  #circle {
    position: relative;
    width: 100%;
    padding-bottom: 0;
    bottom: 0;
    height: 350px;
    margin: 0 auto;
  }

  #circle text {
    font-family: "Avenir", Arial;
    fill: rgb(124, 98, 66);
    font-size: 11.3px;
  }

  #circle svg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;

    -webkit-animation-name: rotate;
    -moz-animation-name: rotate;
    -ms-animation-name: rotate;
    -o-animation-name: rotate;
    animation-name: rotate;
    -webkit-animation-duration: 7s;
    -moz-animation-duration: 7s;
    -ms-animation-duration: 7s;
    -o-animation-duration: 7s;
    animation-duration: 7s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-timing-function: linear;
    -ms-animation-timing-function: linear;
    -o-animation-timing-function: linear;
    animation-timing-function: linear;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-360deg);
    }
  }
`
const StyledImg = styled.img`
  position: absolute;
  top: 0;
  width: 100%;
  padding: 2rem;
`

export default props => {
  console.log(props.data.locale)
  return (
    <Container>
      <StyledImg src={Logo} />
      <div id="container">
        <div id="circle">
          <div className="mobile-logo">
            <img src={Phone} />
          </div>
          <svg
            version="1.1"
            x="0px"
            y="0px"
            width="300px"
            height="300px"
            viewBox="0 0 300 300"
            enableBackground="new 0 0 300 300"
            xmlSpace="preserve"
          >
            <defs>
              <path
                id="circlePath"
                d=" M 150, 150 m -60, 0 a 60,60 0 0,1 120,0 a 60,60 0 0,1 -120,0 "
              />
            </defs>
            <circle cx="150" cy="100" r="75" fill="none" />
            <g>
              <use xlinkHref="#circlePath" fill="none" />
              <text fill="#000">
                <textPath xlinkHref="#circlePath">
                  {props.data.locale === "de"
                    ? "DREHEN SIE IHR DEVICE FÜR EINE OPTIMALE EXPERIENCE"
                    : "ROTATE  YOUR  DEVICE FOR AN OPTIMAL EXPERIENCE"}
                </textPath>
              </text>
            </g>
          </svg>
        </div>
      </div>
    </Container>
  )
}
