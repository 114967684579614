import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"

import Logo from "../images/GRANDAIRE.svg"

import posed from "react-pose"

import { TweenMax, TimelineMax, Power3, Expo, Circ, Power4 } from "gsap"

const PreTitle = styled.span`
  position: absolute;
  display: inline-block;
  width: 100vw;
`

const Spacer = styled.span`
  display: inline-block;
  width: 15vw;
`

const Title = styled.div`
  position: absolute;
  font-family: "Qualey";
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(124, 98, 66);
  font-size: 18rem;
  color: black;
  white-space: nowrap;
  color: transparent;
  height: 100%;
  display: flex;
  align-items: center;
`

const PreTitleContainer = styled.div`
  position: fixed;
  top: 50%;
  z-index: 999;
`

const preTitleContainer = posed.div({
  enter: {},
  leave: {
    transition: { ease: "easeOut", duration: 1000 },
  },
})

const TitleContainer = styled(preTitleContainer)`
  z-index: 999;
  height: 100vh;
  width: ${props => `${props.width}px`};
  display: flex;
  align-items: center;
  pointer-events: none;
`

const ScrollTextThree = props => {
  const titleRef = useRef()
  const [titleWidth, setTitleWidth] = useState(0)
  let titleWidthValue = titleWidth

  //  Initial Mount
  useEffect(() => {
    //Set Container Height to Title Width

    titleWidthValue = Math.floor(titleRef.current.getBoundingClientRect().width)
    setTitleWidth(titleWidthValue)
  }, [])

  useEffect(() => {
    //Set Container Height to Title Width

    titleWidthValue = Math.floor(titleRef.current.getBoundingClientRect().width)
    setTitleWidth(titleWidthValue)
  })

  return (
    <TitleContainer width={titleWidthValue}>
      <Title ref={titleRef}>
        {/* <PreTitle className="preTitle" ref={preTitleRef}>
          <StyledImg src={Logo} />
        </PreTitle> */}
        <Spacer className="spacer" />
        <span>ULTIMATE LUXURY</span>
        <Spacer className="spacer" />
      </Title>
    </TitleContainer>
  )
}

export default ScrollTextThree
