import React, { useEffect, useRef } from "react"

import mapboxgl from "mapbox-gl"

export default props => {
  let mapRef = useRef()
  let map
  mapboxgl.accessToken =
    "pk.eyJ1IjoibWlyY28iLCJhIjoiY2o5bnl4ODhlMTh1bjJ4cXp6dnhuaGRraCJ9.Aa0StG_xkcx3VaGyFewByQ"

  useEffect(() => {
    map = new mapboxgl.Map({
      container: mapRef.current,
      style: "mapbox://styles/mirco/ck3ekpuys4wlp1co5a5v3kw4g",
    })

    // Add zoom and rotation controls to the map.
    map.addControl(new mapboxgl.NavigationControl())

    map["scrollZoom"].disable()
    map["boxZoom"].disable()
    map["doubleClickZoom"].disable()
    map["touchZoomRotate"].disable()

    if (!props.isMobile) {
      map["dragPan"].disable()
    }

    return () => {
      map.remove()
    }
  }, [])

  const style = {
    position: "absolute",
    top: 0,
    bottom: 0,
    width: "100%",
    cursor: "none",
  }

  return <div style={style} ref={mapRef}></div>
}
