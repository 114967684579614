import React, { useEffect, useRef, useState, useContext } from "react"
import styled from "styled-components"
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

import posed from "react-pose"

const preContainer = posed.div({
  enter: {
    y: "0",
    delayChildren: 450,
    staggerChildren: 50,
    delay: 450,
    opacity: 1,
    transition: {
      y: {
        ease: "easeInOut",
        duration: 1500,
      },
      opacity: {
        ease: "easeInOut",
        duration: 1500,
      },
    },
  },
  leave: {
    y: "5%",
    opacity: 0,
    transition: {
      y: {
        ease: "easeInOut",
        duration: 1000,
      },
    },
  },
})

const Container = styled(preContainer)`
  position: absolute;
  background: white;
  height: auto;
  width: 100vw;
  z-index: 999;
`

const TopTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 0;
  color: rgb(124, 98, 66);

  span:nth-child(1) {
    font-family: "Qualey";
    font-size: 1rem;
    line-height: 1rem;
  }

  span:nth-child(2) {
    font-family: "Qualey";
    font-size: 0.5rem;
    line-height: 0.5rem;
  }
`

const StyledImg = styled(Img)`
  height: 90vw;
  width: 90vw;
  margin: 0 auto;

  @media (min-width: 992px) {
    height: 50vh;
    width: 50vh;
  }
`

const ImgText = styled.div`
  font-family: "Avenir";
  font-size: 0.75rem;
  line-height: 0.75rem;
  padding-top: 2.5rem;
  text-transform: uppercase;
  color: #646363;
  text-align: center;
  letter-spacing: 0.1rem;
`

const BigTitle = styled.div`
  font-family: "Qualey";
  font-size: 3.5rem;
  line-height: 3.5rem;
  color: rgb(124, 98, 66);
  text-transform: uppercase;
  text-align: center;
  padding: 5rem 5%;
  hyphens: auto;

  @media (min-width: 992px) {
    font-size: 7.5rem;
    line-height: 7.5rem;
    padding: 5rem 20%;
  }
`

const Text = styled.div`
  font-family: "Avenir";
  font-size: 1.25rem;
  line-height: 2rem;
  padding: 2.5rem 5% 10rem 5%;
  color: #646363;

  @media (min-width: 992px) {
    padding: 2.5rem 20% 10rem 20%;
  }
`

const HotspotModulePage = props => {
  let intl = useIntl()
  const data = useStaticQuery(graphql`
    {
      img1: file(relativePath: { eq: "hotspotpageimages/0.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      img2: file(relativePath: { eq: "hotspotpageimages/1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      img3: file(relativePath: { eq: "hotspotpageimages/2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      img4: file(relativePath: { eq: "hotspotpageimages/3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      img5: file(relativePath: { eq: "hotspotpageimages/4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  let renderImage = page => {
    switch (page) {
      case 0:
        return <StyledImg fluid={data.img1.childImageSharp.fluid} />
        break
      case 1:
        return <StyledImg fluid={data.img2.childImageSharp.fluid} />
        break
      case 2:
        return <StyledImg fluid={data.img3.childImageSharp.fluid} />
        break
      case 3:
        return <StyledImg fluid={data.img4.childImageSharp.fluid} />
        break
      case 4:
        return <StyledImg fluid={data.img5.childImageSharp.fluid} />
        break
      default:
        return null
    }
  }

  let renderTitle = page => {
    switch (page) {
      case 0:
        return (
          <span
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: "pageone.title" }),
            }}
          />
        )
        break
      case 1:
        return (
          <span
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: "pagetwo.title" }),
            }}
          />
        )
        break
      case 2:
        return (
          <span
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: "pagethree.title" }),
            }}
          />
        )
        break
      case 3:
        return (
          <span
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: "pagefour.title" }),
            }}
          />
        )
        break
      case 4:
        return (
          <span
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: "pagefive.title" }),
            }}
          />
        )
        break
      default:
        return null
    }
  }

  let renderText = page => {
    switch (page) {
      case 0:
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: "pageone.text" }),
            }}
          />
        )
        break
      case 1:
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: "pagetwo.text" }),
            }}
          />
        )
        break
      case 2:
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: "pagethree.text" }),
            }}
          />
        )
        break
      case 3:
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: "pagefour.text" }),
            }}
          />
        )
        break
      case 4:
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: "pagefive.text" }),
            }}
          />
        )
        break
      default:
        return null
    }
  }

  useEffect(() => {}, [])

  return (
    <>
      <Container>
        <TopTitle>
          <span>Elegance</span>
          <span>LUXURY</span>
        </TopTitle>
        {renderImage(props.page)}
        <ImgText>
          <span
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: "pageone.imagetext" }),
            }}
          />
        </ImgText>

        <BigTitle>{renderTitle(props.page)}</BigTitle>
        <Text>{renderText(props.page)}</Text>
      </Container>
    </>
  )
}

export default HotspotModulePage
