import React, { useEffect, useRef, useState, useContext } from "react"
import styled from "styled-components"
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

import posed from "react-pose"

import Logo from "../images/GRANDAIRE.svg"

import AppContext from "../context/AppContext"

import CheckMark from "../images/Check-mark.svg"

const axios = require("axios")

const preContainer = posed.div({
  enter: {
    y: "-100%",
    opacity: 1,
    transition: {
      y: {
        ease: "easeInOut",
        duration: 500,
      },
      opacity: {
        ease: "easeInOut",
        duration: 1000,
      },
    },
  },
  leave: {
    y: "100%",
    opacity: 1,
    transition: {
      y: {
        ease: "easeInOut",
        duration: 500,
      },
    },
  },
})

const Container = styled(preContainer)`
  position: absolute;
  background: white;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  opacity: 0;
`

const preForm = posed.form({
  enter: {
    y: "0",
    delayChildren: 450,
    staggerChildren: 50,
    delay: 450,
    opacity: 1,
    transition: {
      y: {
        ease: "easeInOut",
        duration: 1500,
      },
      opacity: {
        ease: "easeInOut",
        duration: 1500,
      },
    },
  },
  leave: {
    y: "5%",
    opacity: 1,
    transition: {
      y: {
        ease: "easeInOut",
        duration: 1000,
      },
    },
  },
})

const FormElement = posed.div({
  enter: {
    y: "0",
    opacity: 1,
    transition: {
      opacity: {
        ease: "easeInOut",
        duration: 1000,
      },
    },
  },
  leave: {
    // y: "0",
    // applyAtEnd: {
    //   y: "50%",
    // },
    opacity: 0,
  },
})

const Form = styled(preForm)`
  position: absolute;
  top: 0;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  padding-top: 4rem;
  padding-bottom: 6rem;
  border-radius: 0 !important;

  @media (min-width: 1025px) {
    width: 50%;
  }

  > div:nth-child(1)  {
    flex-basis: 100%;
  }
  div:nth-child(2)  {
    flex-basis: 100%;
  }
  div:nth-child(3)  {
    flex-basis: 100%;
    position: relative;
  }

  div:nth-child(3) > div {
    position: relative;
    width: 50%;
    margin: 0 auto;
  }

  div:nth-child(4)  {
    flex-basis: 50%;
    padding-right: 1rem;
  }

  div:nth-child(5)  {
    flex-basis: 50%;
    padding-left: 1rem;
  }

  div:nth-child(6)  {
    flex-basis: 50%;
    padding-right: 1rem;
  }

  div:nth-child(7)  {
    flex-basis: 50%;
    padding-left: 1rem;
  }

  div:nth-child(8)  {
    flex-basis: 50%;
    display: flex;
    padding-right: 1rem;
  }

  div:nth-child(9)  {
    flex-basis: 50%;
    padding-left: 1rem;
  }

  div:nth-child(10)  {
    flex-basis: 50%;
    padding-right: 1rem;
  }

  div:nth-child(11)  {
    flex-basis: 50%;
    padding-left: 1rem;
  }

  div:nth-child(12)  {
    position: relative;
    flex-basis: 50%;
    padding-right: 1rem;
  }

  div:nth-child(12) > div {
    position: relative;
    width: 100%;
    margin: 0 auto;
  }

  div:nth-child(13)  {
    position: relative;
    flex-basis: 50%;
    display: flex;
    padding-left: 1rem;
    font-family: "Avenir";
    font-size: 0.7rem;
    line-height: 1rem;
    color: #646363;
    padding-top: 10px;
  }

  div:nth-child(13) input  {
    position: absolute;
    line-height: 0;
    margin-bottom: 0;
    width: 20px;
    height: 20px;
    border: 1px solid rgb(144, 120, 85);
    background: white;
    -webkit-appearance: none;
    z-index: 999;
  }

  div:nth-child(13) input:checked  {
    border: 1px solid rgb(144, 120, 85);
    background-color: rgb(144, 120, 85);
    background-image: url(${CheckMark});
    background-position: center;
    background-repeat: no-repeat;
  }

  div:nth-child(13) span {
    padding-left: 2rem;
    top: 2px;
    position: relative;
  }

  div:nth-child(14)  {
    flex-basis: 100%;
    line-height: 2.1rem;
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  div:nth-child(15)  {
    flex-basis: 100%;
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 4rem;
    margin-top: 2rem;
  }

  div:nth-child(16)  {
    flex-basis: 100%;
    font-family: "Avenir";
    font-size: 0.7rem;
    line-height: 1rem;
    color: #646363;
    margin-top: 1rem;
    height: 1rem;
    letter-spacing: 0.1rem;

    span {
      opacity: 0.7;
    }
  }

  div:nth-child(17)  {
    position: relative;
    flex-basis: 100%;
    display: flex;
    padding-left: 1rem;
    font-family: "Avenir";
    font-size: 0.7rem;
    line-height: 1rem;
    color: #646363;
    padding-top: 2rem;
  }

  div:nth-child(17) div {
    background-color: rgb(249, 249, 249);
    border: 1px solid rgb(211, 211, 211);
    padding: 1rem;
    margin: 0 auto;
  }

  div:nth-child(17) a  {
    font-family: "Avenir";
    font-size: 0.7rem;
    line-height: 1rem;
    color: rgb(144, 120, 85);
  }

  div:nth-child(17) input  {
    position: absolute;
    line-height: 0;
    margin-bottom: 0;
    width: 20px;
    height: 20px;
    border: 1px solid rgb(144, 120, 85);
    background: white;
    -webkit-appearance: none;
    z-index: 999;
  }

  div:nth-child(17) input:checked  {
    border: 1px solid rgb(144, 120, 85);
    background-color: rgb(144, 120, 85);
    background-image: url(${CheckMark});
    background-position: center;
    background-repeat: no-repeat;
  }

  div:nth-child(17) span {
    padding-left: 2rem;
    top: 2px;
    position: relative;
  }

  div:nth-child(18)  {
    position: relative;
    flex-basis: 100%;
    display: flex;
    padding-left: 1rem;
    font-family: "Avenir";
    font-size: 0.7rem;
    line-height: 1rem;
    color: #646363;
    padding-top: 10px;
  }

  div:nth-child(18) input  {
    position: absolute;
    line-height: 0;
    margin-bottom: 0;
    width: 20px;
    height: 20px;
    border: 1px solid rgb(144, 120, 85);
    background: white;
    -webkit-appearance: none;
  }

  div:nth-child(18) input:checked  {
    border: 1px solid rgb(144, 120, 85);
    background-color: rgb(144, 120, 85);
    background-image: url(${CheckMark});
    background-position: center;
    background-repeat: no-repeat;
  }

  div:nth-child(18) span {
    padding-left: 2rem;
  }

  div:nth-child(19)  {
    flex-basis: 100%;
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 4rem;
  }

  div:nth-child(20)  {
    flex-basis: 100%;
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 4rem;
  }

  input {
    font-family: "Avenir";
    border: 0;
    font-size: 0.7rem;
    letter-spacing: 0.2rem;
    text-align: center;
    width: 100%;
    color: #646363;
    border-bottom: 2px solid rgb(144, 120, 85);
    line-height: 2rem;
    // outline: none;
    margin-bottom: 1.5rem;
    border-radius: 0 !important;

    ::placeholder {
      color: #646363;
      opacity: 1;
    }
  }

  select {
    font-family: "Avenir";
    border: 0;
    font-size: 0.7rem;
    letter-spacing: 0.2rem;
    text-align: center;
    width: 100%;
    color: #646363;
    border-bottom: 2px solid rgb(144, 120, 85);
    line-height: 2rem;
    // outline: none;
    margin-bottom: 1.5rem;
    border-radius: 0;
    height: 2.25rem;
    background: white;
    -webkit-appearance: none;
    padding-left: 0.5rem;

    ::placeholder {
      color: #646363;
      opacity: 1;
    }
  }

  option {
    border-radius: 0;
  }

  textarea {
    font-family: "Avenir";
    border: 0;
    font-size: 0.7rem;
    letter-spacing: 0.1rem;
    text-align: center;
    color: #646363;
    width: 100%;
    border-bottom: 2px solid rgb(144, 120, 85);
    margin-top: 1.5rem;
    height: 2rem;
    // outline: none;
    resize: none;

    ::placeholder {
      color: #646363;
      opacity: 1;
    }
  }

  button {
    font-family: "Qualey";
    letter-spacing: 0.2rem;
    font-size: 1.1rem;
    border: 0;
    color: rgb(144, 120, 85);
    text-align: center;
    cursor: pointer;
    outline: none;
    background: none;
    opacity: 1;
    width: fit-content;
    margin: 0 auto;
    opacity: 0.4;
  }

  button:hover {
    opacity: 0.8;
  }

  .arrow-down {
    position: absolute;
    right: 0;
    margin-top: 15px;
    border-left: 0.3rem solid transparent;
    border-right: 0.3rem solid transparent;
    border-top: 0.5rem solid rgb(144, 120, 85);
    pointer-events: none;
  }
`

const Title = styled.div`
  font-family: "Qualey";
  font-size: 1.1rem;
  line-height: 1.1rem;
  color: rgb(124, 98, 66);
  margin: 4rem 0;
`

const ErrorMessage = styled.div`
  font-family: "Avenir";
  font-size: 0.7rem;
  line-height: 1rem;
  color: #646363;
  margin-top: 1rem;
  height: 1rem;
`

const SuccessMessage = styled.div`
  font-family: "Avenir";
  font-size: 0.7rem;
  line-height: 1rem;
  color: #646363;
  margin-top: 1rem;
  height: 1rem;
`

const LogoContainer = styled.div`
  width: 250px;
  margin: 0 auto;
  img {
    height: 100%;
    width: 100%;
    margin: 0;
  }
`

const KontaktModule = props => {
  let intl = useIntl()
  const appContext = useContext(AppContext)
  let checkBox = useRef()

  useEffect(() => {
    //Kontakt Script

    // Declare variables

    var errorMessage = []

    var userData = {}

    // Add Email to database

    let addEmailToDatabase = e => {
      //Prevent default form submission
      e.preventDefault()

      //Get values

      userData = {
        email: {
          value: document.querySelector("#email-enter").value,
          isValid: false,
        },
        telephone: {
          value: document.querySelector("#telephone-enter").value,
          isValid: false,
        },
        title: {
          value: document.querySelector("#title-enter").value,
          isValid: false,
        },
        name: {
          value: document.querySelector("#name-enter").value,
          isValid: false,
        },
        surname: {
          value: document.querySelector("#surname-enter").value,
          isValid: false,
        },
        nachricht: {
          value: document.querySelector("#nachricht-enter").value,
          isValid: false,
        },
        privacypolicy: {
          value: document.querySelector("#privacy-policy").checked,
          isValid: false,
        },
      }

      //Check validity of fields

      checkValidity()

      //Check if all fields have been filled in

      if (
        userData.email.isValid &&
        userData.title.isValid &&
        userData.name.isValid &&
        userData.surname.isValid &&
        userData.telephone.isValid &&
        userData.nachricht.isValid &&
        userData.privacypolicy.isValid
      ) {
        //Post to Formspree
        var data = new FormData(document.getElementById("kontakt-subscribe"))

        axios
          .post("https://formspree.io/xoqapnyr", data)
          .then(function(response) {
            console.log(response)
            //Remove error message
            document.querySelector("#error-message").style.display = "none"
            //Show success message
            document.querySelector("#submit-btn").style.display = "none"
            document.getElementById("kontakt-subscribe").reset()
            document.querySelector("#success-message").innerHTML =
              intl.messages["kontaktform.errorOneBis"]
            document.querySelector("#success-message").style.display = "block"
          })
          .catch(function(error) {
            console.log(error)
            //Show error message
            document.querySelector("#error-message").innerHTML =
              intl.messages["kontaktform.errorOne"]
            document.querySelector("#error-message").style.display = "block"
          })
      } else {
        //Show error message
        document.querySelector("#error-message").innerHTML = `${
          intl.messages["kontaktform.errorTwo"]
        }${errorMessage}`
        document.querySelector("#error-message").style.display = "block"
      }
    }

    document
      .querySelector("#kontakt-subscribe")
      .addEventListener("submit", addEmailToDatabase)

    //Validity function

    const checkValidity = () => {
      //Get values

      userData = {
        email: {
          value: document.querySelector("#email-enter").value,
          isValid: false,
        },
        telephone: {
          value: document.querySelector("#telephone-enter").value,
          isValid: false,
        },
        title: {
          value: document.querySelector("#title-enter").value,
          isValid: false,
        },
        name: {
          value: document.querySelector("#name-enter").value,
          isValid: false,
        },
        surname: {
          value: document.querySelector("#surname-enter").value,
          isValid: false,
        },
        nachricht: {
          value: document.querySelector("#nachricht-enter").value,
          isValid: false,
        },
        privacypolicy: {
          value: document.querySelector("#privacy-policy").checked,
          isValid: false,
        },
      }
      errorMessage = []

      // Check if data privacy is selected
      userData.privacypolicy.value
        ? (userData.privacypolicy.isValid = true)
        : errorMessage.push(
            `${intl.messages["kontaktform.errorPrivacy"]}`,
            ", "
          )

      // Check if email field is populated
      userData.email.value
        ? (userData.email.isValid = true)
        : errorMessage.push("Email", ", ")

      // Check if title field is populated
      userData.title.value
        ? (userData.title.isValid = true)
        : errorMessage.push(`${intl.messages["kontaktform.errorTitle"]}`, ", ")

      //Check if name field is populated without special characters and less than 150 characters
      userData.name.value
        ? userData.name.value.length > 150
          ? errorMessage.push(
              `${intl.messages["kontaktform.errorThree"]}`,
              ", "
            )
          : /^([a-zA-ZäöüÄÖÜß]+[,.]?[ ]?|[a-zA-ZäöüÄÖÜß]+[\'-]?)+$/.test(
              userData.name.value
            )
          ? (userData.name.isValid = true)
          : errorMessage.push(`${intl.messages["kontaktform.errorFour"]}`, ", ")
        : errorMessage.push(`${intl.messages["kontaktform.errorFive"]}`, ", ")

      //Check if surname field is populated without special characters and less than 150 characters
      userData.surname.value
        ? userData.surname.value.length > 150
          ? errorMessage.push(
              `${intl.messages["kontaktform.errorSurnameOne"]}`,
              ", "
            )
          : /^([a-zA-ZäöüÄÖÜß]+[,.]?[ ]?|[a-zA-ZäöüÄÖÜß]+[\'-]?)+$/.test(
              userData.surname.value
            )
          ? (userData.surname.isValid = true)
          : errorMessage.push(
              `${intl.messages["kontaktform.errorSurnameTwo"]}`,
              ", "
            )
        : errorMessage.push(
            `${intl.messages["kontaktform.errorSurnameThree"]}`,
            ", "
          )

      //Check if telephone field is populated without special characters and less than 150 characters
      userData.telephone.value
        ? userData.telephone.value.length > 150
          ? errorMessage.push(`${intl.messages["kontaktform.errorSix"]}`, ", ")
          : true
          ? (userData.telephone.isValid = true)
          : errorMessage.push(
              `${intl.messages["kontaktform.errorSeven"]}`,
              ", "
            )
        : errorMessage.push(`${intl.messages["kontaktform.errorEight"]}`, ", ")

      //Check if nachricht field is populated without special characters and less than 150 characters
      userData.nachricht.value
        ? userData.nachricht.value.length > 150
          ? errorMessage.push(`${intl.messages["kontaktform.errorNine"]}`, ", ")
          : /^([a-zA-Z0-9äöüÄÖÜß]+[,.]?[ ]?|[a-zA-Z0-9äöüÄÖÜß]+[\'-]?)+$/.test(
              userData.nachricht.value
            )
          ? (userData.nachricht.isValid = true)
          : errorMessage.push(`${intl.messages["kontaktform.errorTen"]}`, ", ")
        : errorMessage.push(`${intl.messages["kontaktform.errorEleven"]}`, ", ")

      // If no fields are populated
      if (errorMessage.length === 10) {
        errorMessage = `${intl.messages["kontaktform.errorTwelve"]}`
      } else {
        // else if some fields are populated
        if (errorMessage.length > 2) errorMessage.splice(-2, 0, " ")
        errorMessage.splice(
          -1,
          1,
          `${intl.messages["kontaktform.errorThirteen"]}`
        )
        errorMessage = errorMessage.join("")
      }

      if (
        userData.email.isValid &&
        userData.title.isValid &&
        userData.name.isValid &&
        userData.surname.isValid &&
        userData.telephone.isValid &&
        userData.nachricht.isValid &&
        userData.privacypolicy.isValid
      ) {
        document.querySelector("#error-message").innerHTML = ""
        document.querySelector("#submit-btn").style.opacity = 1
      } else {
        //Show error message
        document.querySelector("#error-message").innerHTML = `${
          intl.messages["kontaktform.errorFourteen"]
        }${errorMessage}`
        document.querySelector("#error-message").style.display = "block"
      }
    }

    document
      .querySelector("#kontakt-subscribe")
      .addEventListener("input", checkValidity)
  }, [])

  useEffect(() => {
    if (appContext.sendPassword) {
      checkBox.current.setAttribute("checked", true)
    }
  })

  return (
    <>
      <Container pose={props.isOpen ? "enter" : "leave"}>
        <Form
          id="kontakt-subscribe"
          action="https://formspree.io/xoqapnyr"
          method="POST"
        >
          <FormElement>
            <LogoContainer>
              <img src={Logo} />
            </LogoContainer>
          </FormElement>
          <FormElement>
            <Title>
              <FormattedMessage id="kontaktform.title" />
            </Title>
          </FormElement>

          <FormElement>
            <div>
              <div class="arrow-down"></div>
              <select name="Andrede" id="title-enter">
                <option value="" disabled selected>
                  {intl.formatMessage({ id: "kontaktform.fieldTitle" })}
                </option>
                <option>
                  {" "}
                  {intl.formatMessage({
                    id: "kontaktform.fieldTitleOptionOne",
                  })}
                </option>
                <option>
                  {" "}
                  {intl.formatMessage({
                    id: "kontaktform.fieldTitleOptionTwo",
                  })}
                </option>
              </select>
            </div>
          </FormElement>

          <FormElement>
            <input
              type="text"
              name="Vorname"
              placeholder={intl.formatMessage({
                id: "kontaktform.fieldSurname",
              })}
              id="surname-enter"
            />
          </FormElement>

          <FormElement>
            <input
              type="text"
              name="Name"
              placeholder={intl.formatMessage({ id: "kontaktform.fieldOne" })}
              id="name-enter"
            />
          </FormElement>

          <FormElement>
            <input
              type="tel"
              name="Telefonnummer"
              placeholder={intl.formatMessage({ id: "kontaktform.fieldTwo" })}
              id="telephone-enter"
            />
          </FormElement>

          <FormElement>
            <input
              type="text"
              name="email"
              placeholder={intl.formatMessage({ id: "kontaktform.fieldThree" })}
              id="email-enter"
            />
          </FormElement>

          <FormElement>
            <input
              type="text"
              name="Strasse"
              placeholder={intl.formatMessage({
                id: "kontaktform.fieldStreetName",
              })}
              id="street-name-enter"
            />
          </FormElement>

          <FormElement>
            <input
              type="text"
              name="Hausnummer"
              placeholder={intl.formatMessage({
                id: "kontaktform.fieldStreetNumber",
              })}
              id="street-number-enter"
            />
          </FormElement>

          <FormElement>
            <input
              type="text"
              name="PLZ"
              placeholder={intl.formatMessage({
                id: "kontaktform.fieldZipCode",
              })}
              id="zipcode-enter"
            />
          </FormElement>

          <FormElement>
            <input
              type="text"
              name="Ort"
              placeholder={intl.formatMessage({ id: "kontaktform.fieldCity" })}
              id="city-enter"
            />
          </FormElement>

          <FormElement>
            <div>
              <div class="arrow-down"></div>
              <select name="Grösse Penthouse" id="size-enter">
                <option value="" disabled selected>
                  {intl.formatMessage({ id: "kontaktform.fieldFour" })}
                </option>
                <option>147 m2</option>
                <option>156 m2</option>
                <option>192 m2</option>
                <option>199 m2</option>
                <option>305 m2</option>
              </select>
            </div>
          </FormElement>

          <FormElement>
            <input
              ref={checkBox}
              type="checkbox"
              name="SENDEN SIE MIR EIN PASSWORT FÜR DEN GESCHÜTZTEN BEREICH"
            />
            <span>
              {intl.formatMessage({ id: "kontaktform.receivePassword" })}
            </span>
          </FormElement>

          <FormElement>
            <input
              type="text"
              name="Wie sind sie auf uns aufmerksam geworden?"
              placeholder={intl.formatMessage({
                id: "kontaktform.fieldHearAboutUs",
              })}
              id="hear-about-us-enter"
            />
          </FormElement>

          <FormElement>
            <textarea
              name="Nachricht"
              placeholder={intl.formatMessage({ id: "kontaktform.fieldFive" })}
              id="nachricht-enter"
            ></textarea>
          </FormElement>

          <FormElement>
            <span>{intl.formatMessage({ id: "kontaktform.required" })}</span>
          </FormElement>

          <FormElement>
            <div>
              <input id="privacy-policy" type="checkbox" />
              <span
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: "kontaktform.privacyPolicy",
                  }),
                }}
              />
            </div>
          </FormElement>

          <FormElement>
            <div
              class="g-recaptcha"
              data-sitekey="6LcMwcQUAAAAAGsJiEV0SGlQGEIEtQxQorcUSMkC"
            ></div>
          </FormElement>

          <FormElement>
            <button className="contact2-form-btn" id="submit-btn">
              {intl.formatMessage({ id: "kontaktform.button" })}
            </button>
          </FormElement>

          <FormElement>
            <ErrorMessage id="error-message"></ErrorMessage>
            <SuccessMessage id="success-message"></SuccessMessage>
          </FormElement>
        </Form>
      </Container>
    </>
  )
}

export default KontaktModule
