import React, { useEffect, useRef, useState, useContext } from "react"
import styled from "styled-components"
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

import AppContext from "../context/AppContext"

import HotspotModuleContent from "./hotspotmodulecontent"

import posed from "react-pose"

const preContainer = posed.div({
  enter: {
    y: "-100%",
    opacity: 1,
    transition: {
      y: {
        ease: "easeInOut",
        duration: 500,
      },
      opacity: {
        ease: "easeInOut",
        duration: 1000,
      },
    },
  },
  leave: {
    y: "100%",
    opacity: 1,
    transition: {
      y: {
        ease: "easeInOut",
        duration: 500,
      },
    },
  },
})

const preSemiCircleClose = posed.div({
  enter: {
    y: "0%",
    x: "-50%",
    opacity: 1,
    transition: {
      y: {
        ease: "easeInOut",
        duration: 350,
      },
      opacity: {
        ease: "easeInOut",
        duration: 350,
      },
    },
  },
  leave: {
    y: "100%",
    x: "-50%",
    opacity: 1,
    transition: {
      y: {
        ease: "easeIn",
        duration: 250,
      },
      opacity: {
        ease: "easeInOut",
        duration: 350,
      },
    },
  },
})

const Container = styled(preContainer)`
  position: absolute;
  background: white;
  height: 100%;
  width: 100vw;
  z-index: 999;
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  opacity: 0;
  top: 100%;
`

const SemiCircleClose = styled(preSemiCircleClose)`
  position: absolute;
  background-color: rgb(124, 98, 66);
  bottom: 0;
  height: 60px;
  width: 120px;
  left: 50%;
  border-top-left-radius: 150px;
  border-top-right-radius: 150px;
  z-index: 999;
  cursor: pointer;

  span {
    position: absolute;
    font-family: "Qualey";
    font-size: 18px;
    top: 25px;
    left: ${props => (props.isEnglish ? "26px" : "31px")};
    letter-spacing: 0.1rem;
    color: white;
    opacity: 1;
    transition: opacity 0.5s;
  }

  :hover span {
    opacity: 0.4;
    transition: opacity 0.3s;
  }
`

const preForm = posed.form({
  enter: {
    y: "0",
    delayChildren: 450,
    staggerChildren: 50,
    delay: 450,
    opacity: 1,
    transition: {
      y: {
        ease: "easeInOut",
        duration: 1500,
      },
      opacity: {
        ease: "easeInOut",
        duration: 1500,
      },
    },
  },
  leave: {
    y: "5%",
    opacity: 1,
    transition: {
      y: {
        ease: "easeInOut",
        duration: 1000,
      },
    },
  },
})

const FormElement = posed.div({
  enter: {
    y: "0",
    opacity: 1,
    transition: {
      opacity: {
        ease: "easeInOut",
        duration: 1000,
      },
    },
  },
  leave: {
    // y: "0",
    // applyAtEnd: {
    //   y: "50%",
    // },
    opacity: 0,
  },
})

const Form = styled(preForm)``

const HotspotModule = props => {
  const appContext = useContext(AppContext)
  let intl = useIntl()
  let [isOpen, setIsOpen] = useState(false)
  const [isClicked, setIsClicked] = useState(false)
  const [isEnglish, setIsEnglish] = useState(false)

  useEffect(() => {
    if (appContext.hotspotClicked) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }

    if (props.data.locale === "de") {
      setIsEnglish(false)
    } else {
      setIsEnglish(true)
    }
  })

  return (
    <>
      <Container pose={isOpen ? "enter" : "leave"}>
        <HotspotModuleContent
          page={appContext.hotspotPageToShow}
          data={props.data}
        />
      </Container>
      <SemiCircleClose
        pose={isOpen ? "enter" : "leave"}
        onClick={() =>
          appContext.toggleHotspotClicked(false, appContext.hotspotPageToShow)
        }
        isEnglish={isEnglish ? false : true}
      >
        <span>{props.data.messages.close}</span>
      </SemiCircleClose>
    </>
  )
}

export default HotspotModule
